import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import os_map from '../../static/OS_map.png';
import { StaticImage } from "gatsby-plugin-image";

const NoticeLink = ({title, date, author, slug}) => {
  return (
    <Link to={`information/${slug}`} className="my-1 w-10/12">
      <article className="overflow-hidden p-2 pr-4 bg-gray-400 rounded-l-lg text-right cursor-pointer">
        <h4 className="text-lg text-black">{title}</h4>
        <p className="text-xs text-gray-800">{(new Date(date)).toLocaleString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' })} by {author}</p>
      </article>
    </Link>
  )
}

function Index({data}) {
  const notices = data.notices.edges;
  
  return (
    <Layout headerClass="relative bg-white">
      <SEO title="Burton upon Stather Parish Council" />
      <div className="min-h-screen pt-24 sm:pt-32 md:pt-64 mb-20 bg-no-repeat flex flex-col items-center carousel">
        <div className="flex flex-col md:flex-row sm:mt-48 md:-mt-8">
          <div className="w-full md:w-2/3 sm:mt-16">
            <header className="container mb-3 text-center">
              <h1 className="text-4_5xl font-thin text-opacity-75 text-black sm:mt-8">
                Welcome to the <br />
                <span className="font-bold text-black text-opacity-100">Parish</span> of{' '}
                <span className="font-black text-black  text-opacity-100">Burton</span>{' '}
                <span className="font-thin text-black text-opacity-100">upon Stather</span>
              </h1>
            </header>

            <div className="container w-full p-4 pl-0 md:text-justify flex">
              <div className="markdown-body">
                <p>
                  <b>The civil parish of Burton upon Stather</b> includes the village of Burton upon Stather and the
                  hamlets of Normanby and Thealby.{' '}
                </p>
                <p>
                  From 1315, the village was a trading port and steamers and ferries visited from Hull to attend the
                  market and fairs. A Royal Order for the detention of spies was issued in 1342, which states{' '}
                  <span className="font-black">Burton upon Stather</span> as a port in the County of Lincoln. At this
                  time it was busier than Gainsborough and may have been the busiest on the River Trent. Unfortunately
                  in 1770, there was a large storm causing much of the town to be destroyed along with the river banks.
                  This caused flooding to the rest of the parish. Another disaster occurred on 20<sup>th</sup> February
                  1777, when the brig 'Phoenix' caught fire and twenty barrels of gunpowder exploded, causing many
                  houses to lose their roofs and damage to the church. Again in 1836, the banks of the River Trent gave
                  way.
                </p>
                <p>
                  During the 19<sup>th</sup> and 20<sup>th</sup> centuries,{' '}
                  <span className="font-black">Burton upon Stather</span> was a large manufacturer of bricks and tiles
                  and ironstone was mined in the neighbouring hamlet of Thealby. By the mid 1920's the Dugout Bus
                  Company had been formed in the village, whose buses had a dark green livery with gold lettering.
                </p>
              </div>
            </div>
            <div className="container w-full p-4 pl-0 markdown-body md:text-justify">
              <h2>History</h2>
              <p>
                The term Stather is of Danish origin and implies a landing-stage. Up until 1914, the river landing was
                used as a calling place by steamers between Gainsborough and Hull.
              </p>

              <p>
                A large slipway of concrete and wood to the north of{' '}
                <span className="font-black">Burton upon Stather</span> was built in 1944 by the 79<sup>th</sup>{' '}
                Armoured Division (United Kingdom) used for testing and training with amphibious Duplex Drive tanks
                during the Second World War.
              </p>

              <p>In 2009 a voluntary group was set up to protect and restore the old Tank Ramp on the River Trent.</p>
            </div>
            <div className="container w-full p-4 pl-0 markdown-body md:text-justify">
              <h2>Land</h2>

              <p>
                The Parish Council owns and rents various pieces of land throughout the parish. These include the
                playing fields, which is a section of land on the outskirts of the village, with facilities including a
                children's play area, multi-use games area, outdoor gym and a pavilion. It is also used for training and
                matches by a local youth football team.
              </p>

              <p>
                Other land cared for by the Parish Council includes the Paddock off{' '}
                <span className="font-black">Burton upon Stather</span> High Street, a play area in Thealby, the
                allotments and burial ground, both in <span className="font-black">Burton upon Stather</span>.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 md:mt-1 bg-gray-700 pt-4 rounded-lg md:rounded-l-lg md:rounded-r-none">
            <div className="grid grid-cols-1 gap-4 justify-items-end">
              <div className="markdown-body w-11/12 text-right pr-2">
                <h2 className={'text-white'}>Notices</h2>
              </div>
              {notices.map(
                ({
                  node: {
                    title,
                    dateGmt,
                    slug,
                    author: {
                      node: { name },
                    },
                  },
                }) => (
                  <NoticeLink title={title} date={dateGmt} author={name} slug={slug} key={slug} />
                )
              )}

              <Link
                className="block py-2 mt-2 font-semibold text-gray-400 bg-transparent focus:outline-none pr-2"
                to={'/information'}
              >
                View all <i className="fal fa-angle-double-right"></i>
              </Link>

              <div className="markdown-body w-11/12 text-right mt-6 pr-2">
                <h2 className={'text-white'}>Parish Walks</h2>
              </div>
              <div className="text-right bg-white mx-auto md:p-4 md:mx-2">
                <a href="https://www.os.uk/election-maps/gb/?x=487920&y=417369&z=7&bnd1=CPC&bnd2=&labels=off">
                  <img src={os_map} alt="OS Map" />
                  Interactive Parish map from
                  <img className="inline-block" src={'./os-logo-maps.png'} alt={'OS Map logo'} />
                </a>
              </div>

              <div className="rounded-lg shadow mx-auto mb-4">
                <Link
                  to={'/walks/brickponds'}
                  className="self-center w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                >
                  Find Walks
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
    query ShortNoticeQuery {
        notices: allWpPost(sort: {order: DESC, fields: date}, filter: {categories: {nodes: {elemMatch: {name: {regex: "", glob: "notice"}}}}}, limit: 5) {
            edges {
                node {
                    title
                    dateGmt
                    slug
                    author {
                        node {
                            name
                        }
                    }
                }
            }
        }
    }
`;


export default Index;

